import { ChevronRightIcon } from "@heroicons/react/solid";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Global/layout";
import SEO from "../components/seo/SEO";

const Press = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicPressRelease {
        edges {
          node {
            data {
              release_title {
                text
              }
              slug
              tag
              cover_image {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO />
      <Layout>
        <section className="contact-options-grid border-t-[1px] border-gray-700">
          <div className="py-2 bg-gray-900"></div>
          <div className="flex flex-col space-y-3 md:space-y-4 bg-gray-900 p-8 md:p-12">
            <h1 className="text-2xl text-blue-500 font-semibold">Press</h1>

            <h2 className="text-3xl text-white">Public Relations</h2>
            <p className="text-base text-gray-400 md:pr-40 leading-relaxed">
              Be the First one to know about the Latest News and Events at
              Kabira Mobility. Subscribe to our Newsletter to get the latest
              Electric News delivered directly to your inbox.
            </p>
          </div>
          <div className="py-2 bg-gray-900"></div>
          <div className="py-1 bg-gray-900"></div>
          <div className="bg-gray-900">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <ul className="col-span-1 p-8 lg:p-12 flex flex-col space-y-3 items-start border-r-[1px] border-gray-700">
                <li className="text-2xl text-blue-500 font-regular">
                  Press Kit
                </li>
                <li className="text-lg font-light text-gray-400">
                  Download our Latest Press Kit
                </li>
                <a
                  href="https://kabira-mobility.notion.site/Press-Kit-eb4dc6837bda42ee8bf58b9d417058ac"
                  target="_blank"
                >
                  <button className="group pt-3">
                    <ul className="flex flex-row w-auto items-center space-x-4 p-3 border-[1px] rounded border-blue-500 bg-gray-900 hover:bg-blue-500">
                      <li className="text-base text-blue-500 group-hover:text-gray-800">
                        Download Now
                      </li>
                      <li>
                        <ChevronRightIcon className="h-6 text-blue-500 group-hover:text-gray-800" />
                      </li>
                    </ul>
                  </button>
                </a>
              </ul>
              <ul className="col-span-1 p-8 lg:p-12 flex flex-col space-y-3">
                <li className="text-2xl text-blue-500 font-regular">
                  Write to Us
                </li>
                <li className="text-lg font-light text-gray-400">
                  Need some more information about Kabira Mobility, write to us
                  at{" "}
                  <a
                    className="text-blue-500 font-regular hover:underline"
                    href="mailto:pr@kabiramobility.com"
                  >
                    pr@kabiramobility.com
                  </a>
                </li>
              </ul>
              <ul></ul>
            </div>
          </div>
          <div className="py-1 bg-gray-900"></div>
          <div className="py-1 bg-gray-900"></div>
          <div className="py-1 bg-gray-900"></div>
          <div className="py-1 bg-gray-900"></div>
          <div className="py-2 bg-gray-900"></div>
          <div className="bg-gray-900 lg:hidden">
            <div className=" relative grid grid-cols-1 lg:grid-cols-2 gap-4 p-4 rounded-2xl">
              {data.allPrismicPressRelease.edges.map((item) => (
                <div className=" relative col-span-1 lg:col-span-2 aspect-w-4 aspect-h-7 lg:aspect-w-16 lg:aspect-h-10 bg-gray-800 rounded-2xl !pb-0">
                  <GatsbyImage image={getImage(item.node.data.cover_image)} 
                  className = "rounded-2xl"/>
                  <div className="absolute top-[400px] inset-3 w-auto h-[260px] sm:h-[280px] lg:w-[300px] bg-gray-700 lg:inset-8 lg:h-auto rounded-2xl shadow-md">
                    <ul className="flex flex-col space-y-4 justify-center h-full items-start p-6 sm:p-8">
                      <h5 className="font-medium uppercase text-base text-blue-500">
                        {item.node.data.tag}
                      </h5>
                      <h2 className="text-gray-300 text-xl sm:text-2xl leading-relaxed font-medium">
                        {item.node.data.release_title.text}
                      </h2>
                      <Link to={item.node.data.slug}>
                        <button className="group">
                          <ul className="flex flex-row items-center space-x-4 p-3 border-[1px] rounded border-blue-500 bg-gray-700 hover:bg-blue-500">
                            <li className="text-base text-blue-500 group-hover:text-gray-800">
                              Read Now
                            </li>
                            <li>
                              <ChevronRightIcon className="h-6 text-blue-500 group-hover:text-gray-800" />
                            </li>
                          </ul>
                        </button>
                      </Link>
                    </ul>
                  </div>
                </div>
              ))}
              
            </div>
          </div>
          <div className="bg-gray-900 hidden lg:block">
            <div className=" relative grid grid-cols-1 lg:grid-cols-2 gap-4 p-4">
              {data.allPrismicPressRelease.edges.map((item) => (
                <div className=" relative col-span-1 lg:col-span-2 lg:aspect-w-16 lg:aspect-h-10 bg-gray-800 rounded-2xl !pb-0">
                  <GatsbyImage image={getImage(item.node.data.cover_image)} 
                  className = "rounded-2xl"/>
                  <div className="absolute w-auto h-[220px] sm:h-[280px] lg:w-[300px] bg-gray-700 lg:inset-8 lg:h-auto lg:rounded-2xl shadow-md">
                    <ul className="flex flex-col space-y-4 justify-center h-full items-start p-6 sm:p-8">
                      <h5 className="font-medium uppercase text-base text-blue-500">
                        {item.node.data.tag}
                      </h5>
                      <h2 className="text-gray-300 text-xl sm:text-2xl leading-relaxed font-medium">
                        {item.node.data.release_title.text}
                      </h2>
                      <Link to={item.node.data.slug}>
                        <button className="group">
                          <ul className="flex flex-row items-center space-x-4 p-3 border-[1px] rounded border-blue-500 bg-gray-700 hover:bg-blue-500">
                            <li className="text-base text-blue-500 group-hover:text-gray-800">
                              Read Now
                            </li>
                            <li>
                              <ChevronRightIcon className="h-6 text-blue-500 group-hover:text-gray-800" />
                            </li>
                          </ul>
                        </button>
                      </Link>
                    </ul>
                  </div>
                </div>
              ))}
              
              
            </div>
          </div>
          <div className="py-2 bg-gray-900"></div>
        </section>
      </Layout>
    </>
  );
};

export default Press;
